import "$styles/index.css";
import "$styles/syntax-highlighting.css";

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";

document.addEventListener("DOMContentLoaded", function () {
  const menuButton = document.querySelector('button[aria-label="Abrir menú"]');
  const closeButton = document.querySelector(
    'button[aria-label="Cerrar menú"]'
  );
  const mobileMenu = document.querySelector("#mobile-menu");

  menuButton.addEventListener("click", function () {
    mobileMenu.classList.toggle("hidden");
  });

  closeButton.addEventListener("click", function () {
    mobileMenu.classList.toggle("hidden");
  });
});

document.addEventListener("DOMContentLoaded", function () {
  const menuButton = document.getElementById("menu-button");
  const languageMenu = document.getElementById("language-menu");

  menuButton.addEventListener("click", function (event) {
    event.stopPropagation();
    languageMenu.classList.toggle("hidden");

    // Apply transition classes
    if (languageMenu.classList.contains("hidden")) {
      languageMenu.classList.remove("opacity-100", "scale-100");
      languageMenu.classList.add("opacity-0", "scale-95");
      menuButton.setAttribute("aria-expanded", "false");
    } else {
      languageMenu.classList.remove("opacity-0", "scale-95");
      languageMenu.classList.add("opacity-100", "scale-100");
      menuButton.setAttribute("aria-expanded", "true");
    }
  });

  document.addEventListener("click", function (event) {
    if (!languageMenu.contains(event.target) && !menuButton.contains(event.target)) {
      languageMenu.classList.add("hidden", "opacity-0", "scale-95");
      languageMenu.classList.remove("opacity-100", "scale-100");
      menuButton.setAttribute("aria-expanded", "false");
    }
  });

  // Add click events for language selection
  document.getElementById("language-es").addEventListener("click", function () {
    changeLanguage("/");
  });
  document.getElementById("language-fr").addEventListener("click", function () {
    changeLanguage("/fr/");
  });
});

function changeLanguage(path) {
  window.location.href = path;
}